import { useRef, useState } from "react";
import { CircularProgress, IconButton, List, ListItem, ListItemText, TextField, Box } from "@mui/material";
import { styled, createTheme, ThemeProvider } from '@mui/system';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import SearchIcon from '@mui/icons-material/Search';
import axios from "axios";
import { useEffect } from "react";

const url = 'https://zanzibar-gold.ru';
let razmerURL = '';
const toSearchPage = (value, isHistory, data, display = false, setSmall, setInputFocus) => {
  if (!display) {
    if (isHistory == 'custom') {

      window.location.replace('/shop/filter/prinadlezhnost-is-zhenskie-or-muzhskie-or-detskie/' + razmerURL + jsonToQueryString(data));
      return
    }
    if (isHistory) {

      axios.get(url + '/local/itr24/scripts/updateHistoryElement.php?fields[SEARCH_STRING]=' + value + '&fields[COUNT_REQUEST]=' + data.counter + '&id=' + data.id + '&fields[REQUEST]=' + JSON.stringify(data.params)).then((result) => {

        window.location.replace('/shop/filter/prinadlezhnost-is-zhenskie-or-muzhskie-or-detskie/' + razmerURL + jsonToQueryString(data.params));

      })
    } else {
      axios.get(url + '/local/itr24/scripts/createHistoryElement.php?fields[COUNT_REQUEST]=1&fields[SEARCH_STRING]=' + value + '&name=' + value + '&fields[REQUEST]=' + JSON.stringify(data.params)).then((result) => {

        window.location.replace('/shop/filter/prinadlezhnost-is-zhenskie-or-muzhskie-or-detskie/' + razmerURL + jsonToQueryString(data.params));
      })
    }
  } else {
    setInputFocus()
    setSmall(false)
  }
}
let timer = 0;
const loadOptions = (value, callback, setOptions, setPreFilter, setHistoryOptions) => {
  callback(true)
  clearTimeout(timer)
  let arr = []
  timer = setTimeout(() => {

    let history = new Promise((resolve, reject) => {
      axios.get(url + '/local/itr24/scripts/getSearchHistory.php?s=' + value).then((result) => {


        let tempRes = []

        if (typeof result.data == 'object') {
          for (const key in result.data) {
            tempRes.push(result.data[key])
          }

        } else {
          tempRes = result.data
        }


        let res = tempRes.map((el) => {
          console.log(el);
          return {
            label: el.PROPERTY_824_VALUE,
            value: { name: el.PROPERTY_824_VALUE, id: el.ID, isHistory: el.isHistory, counter: el.PROPERTY_825_VALUE, params: JSON.parse(el.PROPERTY_826_VALUE.replace(/&quot;/g, '"')) }
          }
        })
        setHistoryOptions(res)
        resolve(false)
      })
    })
    let category = new Promise((resolve, reject) => {

      if (value.length > 1) {
        axios.get(url + '/local/itr24/scripts/finderCategory.php?s=' + value).then((result) => {
          let subRes = result.data;
          let category = subRes.result;

          let needlyVerbs = subRes.needlyVerbs.length > 0 ? subRes.needlyVerbs.join(' ') : ''
          let mbNeedlyVerbs = subRes.mbNeedlyVerbs.length > 0 ? subRes.mbNeedlyVerbs.join(' ') : ''
          let categoryId = (category.length > 0 ? category[0].ID : 0)
          let categoryName = (category.length > 0 ? category[0].NAME : '')
          axios.get(url + '/local/itr24/scripts/finderProperty.php?s=' + needlyVerbs + '&c=' + categoryId + '&mb=' + mbNeedlyVerbs).then((result) => {

            console.log(result);
            setPreFilter(arrayToObject(result.data.filter));


            setOptions(buildResult(result.data, value, categoryName, categoryId))
            resolve(false)
          })
        })
      } else {
        resolve()
      }
    });
    Promise.all([history, category]).then(values => {
      callback(false)
    })
  }, 500);

}
function arrayToObject(arr) {

  let result = {};

  if (Array.isArray(arr)) {
    arr.forEach(element => {
      if (element != null) {
        let key = Object.keys(element)[0];
        let value = Object.values(element)[0];
        if (key == 'size' && value > 0) {

          if (value.includes('.')) {
            razmerURL = 'razmer-is-' + value.replace('.', '-') + '/'

          } else {
            razmerURL = 'razmer-is-' + value + '-0/'

          }


        }

        result[key] = value;
      }

    });

    return result
  } else {
    return arr
  }
}
function buildResult(array, text, categoryName, categoryId) {

  let filter = array.filter;
  let result = array.result;
  let size = 0;
  console.log(array.filter);
  array.filter?.forEach(element => {
    if (Object.keys(element)[0] == 'size') {

      size = Object.values(element)[0];
    }
  })

  let options = [];

  let res = createOptionsArr(result, arrayToObject(filter));
  if (size > 0) {

    res.map(element => {
      if (!element.value.params?.size) {
        element.label += ' ' + size + ' размер';
        element.value.params = Object.assign(element.value.params, { size: size });
      } else {
        element.label += ' размер';
      }
      return element
    })
  }



  return res
}

function sortKeys(data) {
  let result = [];
  for (const key in data) {
    if (key == 'CODE' || key == 'ID' || key.includes('VALUE_ID')) {
      delete data[key]
      continue;
    }
    const element = { key: key, count: data[key].length, sort: 0 };
    setPrefix(element)
    result.push(element)



  }
  result.sort((el1, el2) => { return el1['count'] > el2['count'] ? 1 : -1 });
  let index = 1;
  result.map((el, index) => {

    index++;
    if (el.key == "PROPERTY_TIP_IZDELIYA_VALUE" || el.key == "PROPERTY_VID_UKRASHENIA_VALUE") {
      el.sort = 0;
    } else {
      el.sort = index;
    }
  })
  const key = 'sort';
  return result.sort((el1, el2) => { return el1[key] > el2[key] ? 1 : -1 });
}

function buildNotRequired(data, firstResult) {

  if (!!firstResult.mainEl) {
    let { label, value } = firstResult.mainEl;

    let sortedObj = [
      "PROPERTY_METALL_VALUE",
      "PROPERTY_TIP_IZDELIYA_VALUE",
      "PROPERTY_VID_UKRASHENIA_VALUE",
      "PROPERTY_VSTAVKI_VALUE",
      "PROPERTY_TSVET_METALLA_VALUE",
      "PROPERTY_BRAND_VALUE",
      "PROPERTY_PRINADLEZHNOST_VALUE",
    ]
    let tempArr = []
    sortedObj.forEach(elementKey => {
      let savedKey = elementKey;
      if (!!data[elementKey]) {


        if (!data[elementKey]['required']) {

          data[elementKey].data.forEach(element => {
            if (!element) {
              return
            }
            let labelEl = ''
            let startLabel = element;

            element = element.replace(/\(.+\)/, '').trim();
            if (!element) {
              return
            }
            if (savedKey == 'PROPERTY_VSTAVKI_VALUE') {

              if (element != "Без вставок") {

                element = createTvPad(element)
                elementKey = 'PROPERTY_VSTAVKI';
              } else {
                return
              }

            }
            if (savedKey == 'PROPERTY_METALL_VALUE') {
              element = createRPad(element)
            }
            if (savedKey == 'PROPERTY_TSVET_METALLA_VALUE') {
              element = formatTsvet(element)
            }

            if (savedKey == 'IBLOCK_SECTION_ID') {
              return
            }
            let postfix = ''

            if ((savedKey == 'PROPERTY_TIP_IZDELIYA_VALUE' && value.params.hasOwnProperty("PROPERTY_VID_UKRASHENIA_VALUE")) || (savedKey == 'PROPERTY_VID_UKRASHENIA_VALUE' && value.params.hasOwnProperty("PROPERTY_TIP_IZDELIYA_VALUE"))) {
              return
            }
            console.log(element);
            if (value.params.PROPERTY_PRINADLEZHNOST_VALUE) {
              label = label.replace("украшения", "")
            }
            if (value.params.PROPERTY_VSTAVKI) {
              labelEl = element + label.replace('Украшения', " ");

            }
            labelEl = label + data[savedKey]['prefix'] + element.toLowerCase();

            tempArr.push({ label: labelEl, value: { isHistory: false, name: labelEl, params: Object.assign({ [elementKey]: startLabel }, value.params) } })


          })

        }
      }

    })

    let totalResult = (firstResult.data).concat(tempArr)
    return (totalResult)

  } else {
    let sortedObj = [
      "PROPERTY_METALL_VALUE",
      "PROPERTY_TIP_IZDELIYA_VALUE",
      "PROPERTY_VSTAVKI_VALUE",
      "PROPERTY_TSVET_METALLA_VALUE",
      "PROPERTY_BRAND_VALUE",
      "PROPERTY_PRINADLEZHNOST_VALUE",
    ]
    let tempArr = []
    sortedObj.forEach(elementKey => {
      let savedKey = elementKey;
      if (!!data[elementKey]) {


        if (!data[elementKey]['required']) {

          data[elementKey].data.forEach(element => {
            if (!!element) {

              let startLabel = element;
              element = element.replace(/\(.+\)/, '').trim();
              if (!element) {
                return
              }
              if (savedKey == 'PROPERTY_VSTAVKI_VALUE') {

                if (element != "Без вставок") {
                  element = element.replace(/\(.+\)/, '').trim();
                  element = createTvPad(element)
                  savedKey = 'PROPERTY_VSTAVKI';
                  elementKey = 'PROPERTY_VSTAVKI';
                } else {
                  return
                }

              }
              if (savedKey == 'PROPERTY_METALL_VALUE') {
                element = createRPad(element)
              }
              if (savedKey == 'PROPERTY_TSVET_METALLA_VALUE') {
                element = formatTsvet(element)
              }

              if (savedKey == 'IBLOCK_SECTION_ID') {
                return
              }

              let postfix = ''
              // if (value.params.PROPERTY_PRINADLEZHNOST_VALUE) {

              //   if (savedKey == 'PROPERTY_TIP_IZDELIYA_VALUE') {
              //     return
              //   }
              // }
              console.log(123);
              let labelEl = "Украшения " + ((data[savedKey]?.prefix) ? data[savedKey].prefix : "") + element.toLowerCase();
              tempArr.push({ label: labelEl, value: { isHistory: false, name: labelEl, params: Object.assign({ [savedKey]: startLabel }) } })

            }

          })

        }
      }

    })

    let totalResult = (firstResult.data).concat(tempArr)
    return (totalResult)
  }


}
function buildRequired(data) {
  console.log(data);
  let tempArr = []
  let needReverse = false;
  for (let key in data) {
    let savedKey = key;

    if (data[savedKey]['required']) {
      if (tempArr.length > 0) {
        tempArr.forEach(obj => {
          data[savedKey].data.forEach(element => {
            needReverse = true;
            if (!!element) {
              let startLabel = element;

              if ((obj.value.params?.PROPERTY_METALL_VALUE != undefined && savedKey == 'PROPERTY_TSVET_METALLA_VALUE') || (obj.value.params?.PROPERTY_TSVET_METALLA_VALUE != undefined && key == 'PROPERTY_METALL_VALUE')) {

                return
              }

              if (savedKey == 'PROPERTY_VSTAVKI_VALUE') {
                if (element == "Без вставок") {
                  data[savedKey]['prefix'] = ' '
                } else {
                  element = element.replace(/\(.+\)/, '').trim();
                  element = createTvPad(element)
                  key = 'PROPERTY_VSTAVKI';
                }

              }
              if (savedKey == 'PROPERTY_METALL_VALUE') {
                element = createRPad(element)
              }
              if (savedKey == 'PROPERTY_TSVET_METALLA_VALUE') {
                element = formatTsvet(element)
              }

              if (savedKey == 'IBLOCK_SECTION_ID') {
                return
              }

              let label = obj.label + data[savedKey]['prefix'] + element.toLowerCase();
              tempArr.push({ label: label, value: { isHistory: false, name: label, params: Object.assign({ [key]: startLabel }, obj.value.params) } })

            }
          })
        });
      } else {

        data[key].data.forEach(element => {
          let label = element;
          if (!!element) {
            // if (!data.hasOwnProperty("PROPERTY_TIP_IZDELIYA_VALUE") && !data.hasOwnProperty("PROPERTY_TIP_IZDELIYA_VALUE")) {
            if (savedKey == 'PROPERTY_VSTAVKI_VALUE') {
              label = label.replace(/\(.+\)/, '').trim();

              key = 'PROPERTY_VSTAVKI';
              console.log(456);
              label = "Украшения с " + createTvPad(label).toLowerCase()
            }
            if (key == 'PROPERTY_PRINADLEZHNOST_VALUE') {
              label += ' украшения'
            }
            // }

            tempArr.push({ label: label, value: { isHistory: false, name: element, params: { [key]: element } } })

          }
        });
      }
    }

  }
  if (needReverse) {
    tempArr.reverse()

  }
  return ({ data: tempArr, mainEl: tempArr[0] })

}

function createOptionsArr(data, filter) {
  let options = {}
  let lastStr = ''
  console.log(data);
  if (data.length == 0) {


    if (!!filter?.size) {

      options['size'] = {
        required: true
      }
      options['size']['data'] = [filter?.size]
      options['size']['prefix'] = ''
      options['size']['sort'] = 0
      options['size']['required'] = true
    }
  } else {
    let sorted = sortKeys(data)

    sorted.forEach(element => {

      let temp = data[element['key']];
      if (filter[element['key']]) {

        options[element['key']] = {
          required: true
        }

      } else {
        options[element['key']] = {
          required: false
        }
      }
      options[element['key']]['data'] = temp;
      options[element['key']]['prefix'] = element['prefix'];
      options[element['key']]['sort'] = element['sort'];
    })
  }


  let merged = buildNotRequired(options, buildRequired(options))
  return merged


}

function setPrefix(obj) {

  switch (obj.key) {
    case "PROPERTY_METALL_VALUE":
      obj['prefix'] = ' из '
      break;
    case "PROPERTY_TSVET_METALLA_VALUE":
      obj['prefix'] = ' из '
      break;
    case "PROPERTY_VSTAVKI_VALUE":
      obj['prefix'] = ' с '
      break;
    case "size":
      obj['postfix'] = ' размер '
      obj['prefix'] = ' '
      break;
    default:
      obj['prefix'] = ' '
      break;
  }
  return obj
}

function createTvPad(verb) {

  let arrVerb = verb.split('')
  let okn = arrVerb.pop()
  okn = okn.trim()
  switch (okn) {
    case 'т':

      arrVerb.push(okn)
      arrVerb.push('ом')
      return arrVerb.join('')
    case 'н':
      arrVerb.push(okn)
      arrVerb.push('ом')
      return arrVerb.join('')
    case 'р':
      arrVerb.push(okn)
      arrVerb.push('ом')
      return arrVerb.join('')
    case 'д':
      arrVerb.push(okn)
      arrVerb.push('ом')
      return arrVerb.join('')
    case 'н':
      arrVerb.push(okn)
      arrVerb.push('ом')
      return arrVerb.join('')
    case 'г':
      arrVerb.push(okn)
      arrVerb.push('ом')
      return arrVerb.join('')
    case 'з':
      arrVerb.push(okn)
      arrVerb.push('ом')
      return arrVerb.join('')
    case 'а':
      arrVerb.push('ой')
      return arrVerb.join('')
    case 'ц':
      arrVerb.push(okn)
      arrVerb.push('ом')
      return arrVerb.join('')
    case 'л':
      arrVerb.push(okn)
      arrVerb.push('ом')
      return arrVerb.join('')
    case 'с':
      arrVerb.push(okn)
      arrVerb.push('ом')
      return arrVerb.join('')
    case 'ь':
      arrVerb.push(okn)
      arrVerb.push('ю')
      return arrVerb.join('')
    default:
      break;
  }
}

function createRPad(verb) {
  let mass = verb.split(' ');
  let result = ''
  mass.forEach(verb => {
    let arrVerb = verb.split('')
    let okn = arrVerb.pop()

    switch (okn) {
      case 'о':
        arrVerb.push('a')
        result += arrVerb.join('') + ' '
        break;
      // case 'й':
      //   arrVerb.pop()
      //   arrVerb.push('ого')
      //   result += arrVerb.join('') + ' '
      //   break;
      case 'ь':
        arrVerb.push('и')
        result += arrVerb.join('') + ' '
        break;
      case 'в':
        arrVerb.push('ва')
        result += arrVerb.join('') + ' '
        break;
      case 'й':
        arrVerb.push('я')
        result += arrVerb.join('') + ' '
        break;
      default:
        arrVerb.push(okn)
        result += arrVerb.join('') + ' '
        break;
    }
  })
  return result;
}

function formatTsvet(verbs) {
  let mass = verbs.split(' ');
  let result = ''
  mass.forEach(verb => {
    let arrVerb = verb.split('');
    let okn = arrVerb.pop()
    switch (okn) {
      case 'о':
        arrVerb.push('a')
        result += arrVerb.join('') + ' '
        break;
      case 'ь':
        arrVerb.push('и')
        result += arrVerb.join('') + ' '
        break;
      case 'й':
        arrVerb.push('я')
        result += arrVerb.join('') + ' '
        break;
      case 'е':
        arrVerb.push('го')
        result += arrVerb.join('') + ' '
        break;
      case 'и':
        arrVerb.push('ёх')
        result += arrVerb.join('') + ' '
        break;
      case 'а':
        arrVerb.push('ов')
        result += arrVerb.join('') + ' '
        break;
      default:
        result += arrVerb.join('') + ' '
        break;
    }
  });
  return result
}
const SearchFragment = function (props) {
  const { preFilter, display, setSmall, setInputFocus } = props;
  return <IconButton onClick={() => toSearchPage('', 'custom', preFilter, display, setSmall, setInputFocus)}>
    <SearchIcon />
  </IconButton>
}

const SearchTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    padding: 0,
    '& input': {
      // transition: "all 0.5s",
    },
    [theme.breakpoints.down('xl')]: {
      '& fieldset': {
        borderColor: 'rgba(228, 0, 135, 0.4)',
      }
    },
    '&.Mui-focused fieldset': {
      borderColor: 'rgba(228, 0, 135, 0.5)',
    },
  },
}))


const DisplayResult = function (props) {
  const { products, label } = props;
  return products.length > 0 && <Box>
    {label && <Box sx={{ padding: '15px', background: 'aliceblue' }}>{label}</Box>}
    <List sx={{

      padding: 0, flexDirection: 'column', width: '100%',

    }}>
      {products.map((el) => {

        return <ListItem key={el.value.name} sx={{ width: '100%', background: 'white', '&:hover': { background: 'rgba(228, 0, 135, 0.1)' }, cursor: 'pointer', zIndex: '100' }} onClick={() => {
          toSearchPage(el.value.name, el.value.isHistory, el.value)
        }}>
          <ListItemText primary={el.label} />
        </ListItem>
      }
      )}
    </List>
  </Box>

}

function App() {
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState([])
  const [historyOptions, setHistoryOptions] = useState([])
  const [loading, setLoading] = useState(false)
  const [preFilter, setPreFilter] = useState({})
  const [inputValue, setInputValue] = useState('')
  const [small, setSmall] = useState(false)
  const [inputRef, setInputFocus] = useFocus()
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('xl'));
  useEffect(() => {
    setSmall(matches)
  }, [matches])

  return (<div className="App" >

    <SearchTextField
      className={small ? "search-results" : ""}
      placeholder="Поиск по каталогу"
      onFocus={() => {
        setOpen(true)
        setLoading(true)
        loadOptions(inputValue, setLoading, setOptions, setPreFilter, setHistoryOptions)
      }}
      inputRef={inputRef}
      onChange={(e) => { setLoading(true); setInputValue(e.target.value); loadOptions(e.target.value, setLoading, setOptions, setPreFilter, setHistoryOptions) }}
      onBlur={() => {
        setTimeout(() => {
          setOpen(false)
          if (matches) {
            setSmall(true)
          }
        }, 200);
      }}
      size="small" onKeyDown={(e) => {
        if (e.key === 'Enter') {
          toSearchPage('', 'custom', preFilter)
        }
      }}
      sx={{
        width: "100%"
      }}
      InputProps={{
        endAdornment: (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end', width: 'auto' }}>
            {loading && <CircularProgress size={20} color={'inherit'} />}
            <SearchFragment display={small} preFilter={preFilter} setSmall={setSmall} setInputFocus={setInputFocus} />
          </Box>
        ),
      }}
    />
    {!loading && open && <Box
    className="search-result-list"
    sx={{
      display: 'flex', marginTop: '5px',  maxHeight: '300px', border: '2px solid', borderRadius: '15px', borderColor: 'rgba(228, 0, 135, 0.2)', overflowY: 'auto', flexDirection: 'column', position: 'absolute', zIndex: '100', background: 'white'
    }}>
      <Box sx={{
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          width: '5px',
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: 'rgba(0,0,0,.05)',
          borderRadius: '5px'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(228, 0, 135, 0.1)',
          borderRadius: '5px'
        },
      }}>
        <DisplayResult products={historyOptions} label={false} />
        <DisplayResult products={options} label={"Все результаты"} />
      </Box>
    </Box>

    }
  </div>
  );
}

function jsonToQueryString(json) {

  return '?' +
    Object.keys(json).map(function (key) {
      return encodeURIComponent(key) + '=' +
        encodeURIComponent(json[key]);
    }).join('&');
}

const useFocus = () => {
  const htmlElRef = useRef(null)
  const setFocus = () => {
    console.log(htmlElRef.current);
    { htmlElRef.current && htmlElRef.current.focus() }
  }

  return [htmlElRef, setFocus]
}

export default App;